<template>
  <div class="performance-objectives">
    <el-row class="table-search">
      <el-col :span="24">
        <el-form :model="searchCondition" :inline="true" size="small">
          <el-form-item>
            <el-date-picker
                @change="changeOption"
                v-model="searchCondition.year"
                type="year"
                format="yyyy"
                value-format="yyyy"
                placeholder="选择年度" style="width: 140px">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-popover
                placement="right"
                width="500"
                trigger="click"
            >
              <el-button size="small" type="default" slot="reference">
                选择季度<span v-if="searchCondition.quarter">: 第 {{ searchCondition.quarter }} 季度</span>
              </el-button>
              <el-row>
                <el-col :span="24">
                  <el-radio-group v-model="searchCondition.quarter" @change="changeQuarter">
                    <el-radio-button v-for="(quarter) in quarters" :label="quarter.value" :key="quarter.label">
                      {{ quarter.label }}
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
              </el-row>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <DeptSelectTree :searchCondition='searchCondition' ref='deptTree' style='width: 360px'
                            :isType="'target'" :isCheckStrictly="false"
                            @handleSelect='handleChangeDept'></DeptSelectTree>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click='initTable' icon='el-icon-search'>查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon='el-icon-setting'
                       v-hasAuthority="'pa_kpi_kol'" @click='showKolSetting'>
              红人管理
            </el-button>
          </el-form-item>
          <el-form-item>
            <LogPanel :infoIds="kpi_dept_id" buttonSize="small" ref='refLogPanel'
                      v-if="kpi_dept_id!==null" module-name='pa_kpi'></LogPanel>
          </el-form-item>
          <el-form-item style="float: right">
            <el-button type='warning' icon='el-icon-refresh'
                       v-has-authority="'pa_kpi_dept'" @click='showPaDeptInit'>项目部门管理
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <BonusTable ref="BonusTable" @editVal="editVal" :searchCondition="searchCondition"></BonusTable>
    </el-row>
    <el-row style="margin-top: 10px">
      <el-button size="medium" v-has-authority="'pa_kpi_target__release'" style="float: right" type="primary"
                 @click="handleSubmit">发布绩效目标
      </el-button>
    </el-row>
    <el-row :gutter="20" style="margin-top: 20px">
      <el-col>
        <span class="title">
          <i class="el-icon-info" style="font-size: 14px;color: #ff3176"></i>
          备注
        </span>
      </el-col>
      <el-col :span="6" style="margin-top: 10px">
        <table class="table">
          <thead>
          <tr>
            <th>利润规模</th>
            <th>基础奖金包</th>
            <th>最高</th>
          </tr>
          </thead>
          <tbody>
          <tr style="border: 1px solid red">
            <td>2W以内</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>2W(含)-10W</td>
            <td>{{ valFormat(10000) }}</td>
            <td>{{ valFormat(20000) }}</td>
          </tr>
          <tr>
            <td>10W(含)-20W</td>
            <td>{{ valFormat(20000) }}</td>
            <td>{{ valFormat(40000) }}</td>
          </tr>
          <tr>
            <td>20W(含)-40W</td>
            <td>{{ valFormat(25000) }}</td>
            <td>{{ valFormat(50000) }}</td>
          </tr>
          <tr>
            <td>40W(含)-60W</td>
            <td>{{ valFormat(30000) }}</td>
            <td>{{ valFormat(60000) }}</td>
          </tr>
          <tr>
            <td>60W(含)-80W</td>
            <td>{{ valFormat(35000) }}</td>
            <td>{{ valFormat(70000) }}</td>
          </tr>
          <tr>
            <td>80W(含)</td>
            <td>{{ valFormat(50000) }}</td>
            <td>{{ valFormat(100000) }}</td>
          </tr>
          <tr>
            <td>100W(含)</td>
            <td>{{ valFormat(60000) }}</td>
            <td>{{ valFormat(120000) }}</td>
          </tr>
          </tbody>

        </table>
      </el-col>
      <el-col :span="6" style="margin-top: 10px">
        <table class="table">
          <thead>
          <tr>
            <th>完成率</th>
            <th>系数</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>小于100%</td>
            <td>0</td>
          </tr>
          <tr>
            <td>100%（含）- 200%</td>
            <td>系数等于完成率</td>
          </tr>
          <tr>
            <td>大于等于200%</td>
            <td>2</td>
          </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
    <PaKolSetting @close="initTable" :type="1" ref='refPaKolSetting'></PaKolSetting>
    <PaDeptInit ref='refPaDeptInit'/>

  </div>

</template>

<script>

import {mapGetters} from "vuex";
import BonusTable from "@/pages/mcn_performance_data/component/BonusTable.vue";
import PaKolSetting from "@/pages/mcn_performance_data/component/PaKolSetting.vue";
import PaDeptInit from "./component/PaDeptInit.vue";
import DeptSelectTree from "@/pages/mcn_performance_data/component/DeptSelectTree.vue";
import {numberFormat} from "@/utils/utils";
import LogPanel from "@/components/logs/LogPanel.vue";

export default {
  name: "PerformanceObjectives",
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  components: {
    LogPanel,
    DeptSelectTree,
    PaDeptInit,
    PaKolSetting,
    BonusTable,
  },
  data() {
    return {
      searchCondition: {year: null, group_id: null, group_name: '', months: [], quarter: 1, type: 1},
      year: null,
      quarters: [
        {label: '第1季度', value: 1},
        {label: '第2季度', value: 2},
        {label: '第3季度', value: 3},
        {label: '第4季度', value: 4},
      ],
      kpi_dept_id:null
    }
  },
  created() {
    this.searchCondition.year = (new Date()).getFullYear() + ''
    // 获取当前季度：
    this.searchCondition.quarter = this.$utils.getCurrentQuarter()
    this.changeQuarter()
  },
  methods: {
    handleChangeDept(obj) {
      this.searchCondition.group_id = obj.dept_id || null
      this.searchCondition.dept_name = obj.dept_name || ''
      this.kpi_dept_id = obj.kpi_dept_id || ''

    },
    async currentGroup(group) {
      if (group && group.dpt_id) {
        await this.setGroupInfo(group.dpt_id, group.name)
      }
    },
    async setGroupInfo(group_id, group_name) {
      // this.$refs['refDeptCascaderScope'].initVal(group_id)
      this.searchCondition.group_id = group_id;
      this.searchCondition.group_name = group_name;
    },
    /**
     * 部门选择：val-label
     * @param valArr
     * @param labelArr
     * @returns {Promise<void>}
     */
    async selectDept(valArr, labelArr) {
      let group_id = null;
      let group_name = null;
      if (valArr && valArr.length > 1) {
        let len = valArr.length;
        group_id = valArr[len - 1];
        group_name = labelArr[len - 1];
      }
      await this.setGroupInfo(group_id, group_name)
    },

    changeQuarter() {
      //计算季度对应的月份
      let quarter = this.searchCondition.quarter
      this.searchCondition.months = [quarter * 3 - 2, quarter * 3 - 1, quarter * 3];//月份的集合
      this.changeOption()
    },
    showKolSetting() {
      this.$refs['refPaKolSetting'].show(this.searchCondition)
    },
    showPaDeptInit() {
      this.$refs['refPaDeptInit'].show(this.searchCondition)
    },
    async initTable() {
      await this.$refs['BonusTable'].initList()

    },
    async editVal(colName, val) {
      if (['lately_quarter_income', 'bef_two_quarter_profit'].includes(colName)) {
        let update = {
          ...this.searchCondition, ...{
            [colName]: val[colName],
            artist_id: val['artist_id'],
            nickname: val['nickname'],
            group_id: val['group_id'],
            group_name: val['group_name'],
            dept_name: val['dept_name'],
            id: val['id']
          }
        }
        let data = await this.$api.EditTargetData(update)
        if (!data) {
          this.$message.error('修改失败')
        }
      } else if (['target', 'target_first', 'target_second', 'target_third'].includes(colName)) {
        let teamData =
            {
              target: val['target'],
              dept_id: val['group_id'],
              group_name: val['group_name'],
              target_first: val['target_first'],
              target_second: val['target_second'],
              target_third: val['target_third'],
              date_value: this.searchCondition.quarter,
              year: this.searchCondition['year'],
              type: this.searchCondition.type
            }
        //项目组目标
        let id = await this.$api.saveTargetData(teamData)
        if (!id) {
          this.$message.error('修改失败')
        }
      }
      await this.initTable()
    },
    async handleSubmit() {
      let data = {
        year: this.searchCondition.year,
        date_value: this.searchCondition.quarter,
        dept_id: this.searchCondition.group_id,
        type: 1
      }
      this.$confirm(
          `确定发布第${this.searchCondition.quarter}季度绩效吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
        // // releaseTargetData
        let id = await this.$api.releaseTargetData(data)
        if (!id) {
          this.$message.error('修改失败')
        } else {
          this.$message.success('发布成功')
        }
      })
    },
    changeOption() {
      if (this.$refs['deptTree'])
      this.$refs['deptTree'].show(this.searchCondition)
    },
    valFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
  },

  mounted() {
    this.changeOption()
  },
}
</script>

<style lang="scss" scoped>
.title {
  margin-top: 5px;
  margin-left: 10px;
  font-size: 12px;
}

.tips {
  margin-top: 10px;
}

//table, th, td {
//  border: 1px solid black;
//  border-collapse: collapse; /* 移除单元格间的间隔 */
//}

table {
    border: 1px solid black;

  width: 100%;
  border-collapse: collapse;
}
th, td {
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
}
/* 设置不同的背景色 */
.header1 {
  background-color: #f2a6a6; /* 你可以修改为任何你喜欢的颜色 */
}
.header2 {
  background-color: #a6d6f2; /* 你可以修改为任何你喜欢的颜色 */
}
/* 隔列换背景色 */
tr:nth-child(even) td:nth-child(odd),
tr:nth-child(odd) td:nth-child(even) {
  background-color: #f2f2f2;
}
</style>
